
import { Component, Vue } from 'vue-property-decorator';
import SimplePagination from '@/components/simple-pagination/simple-pagination.vue';
import { Channel } from '../../entities/channel.entity';
import ChannelService from '../../services/channel.service';

@Component({
  components: {
    SimplePagination,
  },
})
export default class ChannelListComponent extends Vue {
  private channels: Channel[] = [];

  private get headers(): any {
    const items = [
      { text: 'Descrição', value: 'description', width: '50%' },
      {
        text: 'Status',
        value: 'activeText',
      },
      {
        text: 'Ações',
        width: '10%',
        align: 'center',
        value: 'actions',
      },
    ];

    if (this.$permissionAccess.group.isSuperAdmin) {
      items.splice(1, 0, { text: 'Empresa', value: 'company.name', width: '20%' });
    }
    return items;
  }

  private goToFormEdit(channel: Channel) {
    this.$router.push({
      name: 'channel-edit',
      params: {
        id: channel.id,
      },
    });
  }

  private goToFormCreate() {
    this.$router.push({
      name: 'channel-create',
    });
  }

  private async getChannels() {
    const channels = await ChannelService.getAllChannelsPaginated();
    this.channels = channels.map((channel: Channel) => new Channel(channel));
  }

  public async backPagination() {
    if (!ChannelService.simplePaginationService.prevLink) return;
    const channels = await ChannelService.simplePaginationService.prev();
    this.channels = channels.map((channel: Channel) => new Channel(channel));
  }

  public async nextPagination() {
    if (!ChannelService.simplePaginationService.nextLink) return;
    const channels = await ChannelService.simplePaginationService.next();
    this.channels = channels.map((channel: Channel) => new Channel(channel));
  }

  public async goToFirst() {
    if (!ChannelService.simplePaginationService.firstLink) return;
    const channels = await ChannelService.simplePaginationService.goToFirst();
    this.channels = channels.map((channel: Channel) => new Channel(channel));
  }

  private async created() {
    this.getChannels();
  }
}
